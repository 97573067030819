import React from "react";

import Carousel from 'react-bootstrap/Carousel';

function CarouselFade() {
  return (
    <Carousel fade className="home-main-slides">
      <Carousel.Item>
        <img
          className="d-block w-100"
          // src="./images/1.jpg"
          src="./images/31a.jpeg"
          alt="First slide"
        />
        <Carousel.Caption>
          <h3 className="slide-h3">Together As One Investments Ltd</h3>
          {/* <p className="slide-p slide-p1">Dream It, Build It</p> */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          // src="./images/43.jpeg"
          src="./images/19.jpeg"
          alt="Second slide"
        />

        <Carousel.Caption>
          {/* <h3>We're Prominent In Engineering Works</h3> */}
          <p className="slide-p slide-p2"> We Import & Supply Agro-Products</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          // src="./images/53.jpeg"
            src="./images/3a.jpeg"
          alt="Third slide"
        />

        <Carousel.Caption>
          {/* <h3>We Plan, Schedule, Execute & Control Projects
          </h3> */}
          <p className="slide-p">
           We are known for Quality, Reliability & Excellence
          </p>
        </Carousel.Caption>

      </Carousel.Item>


      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/8.jpg"
          alt="Third slide"
        />
          <Carousel.Caption>
          {/* <h3>We Plan, Schedule, Execute & Control Projects
          </h3> */}
          <p className="slide-p slide-p4">
        We Are Prominent In Construction Works
          </p>
        </Carousel.Caption>
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/9.jpg"
          alt="Third slide"
        />

          <Carousel.Caption>
          {/* <h3>We Plan, Schedule, Execute & Control Projects
          </h3> */}
          <p className="slide-p slide-p4">
      We Are Ready To Serve You With Passion 
          </p>
        </Carousel.Caption>

          
        
      </Carousel.Item>
      
    </Carousel>
  );
}

export default CarouselFade;