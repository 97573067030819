import Carousel from 'react-bootstrap/Carousel';

function RoadCarousel() {
  return (
    <Carousel indicators={false} className='services-slides projects-slides projects-slides-a'>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/6.jpeg"
          alt="First slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/13.jpeg"
          alt="First slide"
        />
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/16.jpeg"
          alt="First slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/33.jpeg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/29a.jpeg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/29b.jpeg"
          alt="First slide"
        />
      </Carousel.Item>


       
  
    </Carousel>
  );
}

export default RoadCarousel;