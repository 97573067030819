import React, {useEffect} from 'react'

const Clients = () => {
useEffect(()=>{
document.title = 'Clients | Mal Kat Terminal Services Ltd';

},[]);



  return (
    <section className='section-cover clients-cover' >
          <h2 className="home-h2 home-h2-a">Our Clients</h2>
          {/* <p className='clients-para'><strong>RAK ENGINEERING LTD</strong> has in the recent past worked on a number of projects and has registered commendable success among clients that include:</p> */}
        <div className='content-container clients'>

           <article className='client'>
                      <img src='./images/21.png' alt='client' className='client-image'></img>
           </article>

            <article className='client'>
                      <img src='./images/22.png' alt='client' className='client-image'></img>
           </article>

            <article className='client'>
                      <img src='./images/23.png' alt='client' className='client-image'></img>
           </article>


            <article className='client'>
                      <img src='./images/24.png' alt='client' className='client-image'></img>
                       <p><strong>Ministry of Defence and Veteran Affairs</strong></p> 
            </article>

            <article className='client'>
                      <img src='./images/25.jpg' alt='client' className='client-image'></img>            
            </article>

            <article className='client'>
                      <img src='./images/26.png' alt='client' className='client-image'></img>            
            </article>

             <article className='client'>
                      <img src='./images/27.png' alt='client' className='client-image'></img>            
            </article>

            

          
          {/* 
            <article className='client'>
                      <img src='./images/TERRAIN.png' alt='client' className='client-image'></img>
                        <p><strong>TERRAIN SERVICES LTD</strong></p>
            </article> */}
          
          </div> 
    </section>
  )
}

export default Clients