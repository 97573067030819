import React,{useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel';
import Plumbing from '../components/plumbing';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaCheckCircle} from 'react-icons/fa';
import {BsBarChartFill} from 'react-icons/bs'
import VisionCarousel from '../components/Vision Carousel';
let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

useEffect(()=>{
document.title = 'Together As One Investments Ltd';

},[]);


  return (
    <>
    <section>
      <CarouselFade></CarouselFade>
    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container '>
          <h2 className="home-h2">Background</h2>
          <div className='line1'>
                    {/* <div className='line2'></div> */}
          </div>

                 <p className='home-p'><strong>Together As One Investments Ltd</strong> is a prominent and duly registered company in Uganda established in 2008. Our main objective is to provide quality services with passion, excellence and integrity. Our services include Import & Supply of Agro Products, Construction Works and General Supplies. Our Products include Coffee, Maize, Diary Products, Bakery Products, Sugar, etc.
</p>
<p className='home-p'>
We ensure that projects are undertaken within the work plan and budget and with a corporative attitude. We are known for quality, innovation and reliability!</p>


{/* <p className='home-p'>
</p> */}

 
        </div>

    </section>
     <section className='home-service-cover'>
    {/* <h2 className='home-h2 home-service-h2'>Overview of  
    Optical Fiber our expertise</h2> */}
     <h2 className='home-h2 home-service-h2'>Our Products</h2>
    <div className='line1'> 
    {/* <div className='line2'></div> */}
    </div>
   
   
   
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div> */}
          <h3 className='home-service-title'>Coffee</h3>
          <PowerCarousel></PowerCarousel>
          {/* <img src='./images/6a.jpg' className='home-service-image'></img> */}
          {/* <p>
            We are passionate about our work and our strength lies in working as a team to engage with our clients and deliver first class customer service and the highest standards of excellence.
            </p> */}
            {/* <Link to='#' className='home-link' onClick={scrollFunc}>See Details</Link> */}

        </div>
        
       
        <div className='home-service-box'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div> */}
          <h3 className='home-service-title'>Maize Flour</h3>
           <TelecomCarousel></TelecomCarousel>
          {/* <img src='./images/1.jpg' className='home-service-image'></img> */}
          {/* <Link to='plumbing-services' className='home-link' onClick={scrollFunc}>See Details</Link> */}
          {/* <p>
            We are passionate about our work and our strength lies in working as a team to engage with our clients and deliver first class customer service and the highest standards of excellence.
            </p> */}

        </div>
        <div className='home-service-box'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div> */}
          <h3 className='home-service-title'>Diary Products</h3> 
          {/* <TelecomCarousel></TelecomCarousel> */}
          {/* <img src='./images/5.jpg' className='home-service-image'></img> */}
             <img src='./images/34.jpg' className='home-service-image'></img>
          {/* <Link to='#' className='home-link' onClick={scrollFunc}>See Details</Link> */}
          {/* <p>
            We are passionate about our work and our strength lies in working as a team to engage with our clients and deliver first class customer service and the highest standards of excellence.
            </p> */}

        </div>
        <div className='home-service-box'>
            {/* <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div> */}
          <h3 className='home-service-title'>Bakery Products</h3>
          {/* <img src='./images/4.jpg' className='home-service-image'></img> */}
           <img src='./images/35.png' className='home-service-image'></img>
          {/* <Link to='#' className='home-link'>See Details</Link> */}
          {/* <p>
            We are passionate about our work and our strength lies in working as a team to engage with our clients and deliver first class customer service and the highest standards of excellence.
            </p> */}

        </div>
       
      
     
{/* 
        <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck>  </span>
          </div>
          <h3 className='home-service-title'>Certifications</h3>
          <p>
            As a company we are registered with the relevant authorities and our entire workforce holds the appropriate qualifications and certifications.
            </p>
        </div> */}

    </div>
        {/* <Link to="services" className='home-btn'>
         Our Services
         </Link> */}


        </div>


   



  </section>
  <section className='section-cover services-cover' >
      {/* <h2 className="home-h2 services-h2">Our Services</h2> */}
           <div className='content-container services-container '>
              

         

            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>The director was in Egypt for Agricultural Exhibition</h3>
                       {/* <p><strong>Beda Civil Works & Contractors Limited</strong> continues its success by offering an unparalleled level of diversity and experience in Construction and Maintenance of Road Infrastructure. </p>                 
                <p> We have the capacity and skills to undertake any road construction or repair project. We have excellent expertise in Structural & Geometric Road Design, Road & Highway Construction, Surface Dressing, Asphalt and concrete paving, Pothole Patching, General Road and Highway Maintenance and Road Marking & Signs.</p> */}
                  {/* <ul>
                   <li>Facility Water Supply Network Design.</li>   
<li> Water Heater Services.</li>
<li>Leakage repairs.</li>
<li>Angle stop repairs.</li>
<li>Toilet and bathroom repairs.</li>
<li>Sewer Main Installation and repair.</li>
<li> Drain Cleaning.</li>
<li>Gas piping.</li>
</ul> */}
                  </article>

                  <article className='service-slides'>
                    <RoadCarousel></RoadCarousel>
                 
                  </article>     
            </div>

            {/* <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>IT Services</h3>
                  <p>We are trusted in bridge construction in Uganda. Our expertise is in delivery of innovative and quality solutions for bridges. Over the years, we have successfully executed several projects in different parts of Uganda.</p>
                        <ul>
                 <li>Fiber Systems.</li> 
<li>CCTV Systems. <Link className='read-more-link'>Read more...</Link></li> 
<li>Wall and Ceiling projectors.</li> 
<li> Structured Cabling. <Link className='read-more-link'>Read more...</Link></li> 
<li>Wi-Fi Solutions. <Link className='read-more-link'>Read more...</Link></li> 
<li>Rack Cabinets and Accessories.</li> 
<li>ICT equipment servicing and repair.</li> 

</ul>
                  </article>

                  <article className='service-slides'>
                    <SupportCarousel></SupportCarousel>
                 
                  </article>     
            </div> */}

            {/* <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Surveying <br></br>(Quantity & Land Surveying)</h3>
                
                <p>We provide our clients with exceptional, full service land surveying services and solutions. An accurate, forward looking, detailed land survey, conducted by experienced and knowledgeable professionals, is essential to the success and efficiency of planning, design, and site development.</p>
                  <p>If you’re a current or future homeowner, there are many of our services you can take advantage of. From the survey you need when you buy a new house, to locating your property corners so that you can build that new fence, we can serve you.</p>
                  <p>Are you planning a commercial project? We are experienced in offices, retail and residential developments and many others, from boundary surveys to annexation, subdividing, site design and construction layout.</p>
                  </article>

                  <article className='service-slides'>
                    <MaintCarousel></MaintCarousel>
                 
                  </article>     
            </div> */}


            {/* <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Architectural Works</h3>
                     <p> If you are looking for beautiful unique building designs, our skills are right for you beautifully adopted for everyone’s design tastes, and can be as simple or complex as you want it to be.</p>
                     <p>We truly care about our users & end products which is why our clients love our work! Our work includes production of many different designs. Each design stands the test of time and saves a developer’s money with all the design concepts we provide.</p>
                  </article>

                  <article className='service-slides'>
                    <EmergencyCarousel></EmergencyCarousel>
                 
                  </article>     
            </div> */}

              {/* <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Paving Works
</h3>
                <p>
                We understand that a paved area is an investment, and we believe it’s a good one. If done correctly, a properly paved area will withstand the elements of both man and nature for many years. It will also add value to your home or business and help you make a great first impression on your visitors.
                </p>
                <p>If you’re in need of a new paved area, give us a call. Before we begin our paving process, we’ll talk to you about your vision and offer our professional opinion on things like placement, size, and price. We’ll also make sure that any drainage issues are taken care of and that your property has a solid foundation so that cars can drive on it safely. </p>
                  </article>

                  <article className='service-slides'>
                    <BuildingsCarousel></BuildingsCarousel>
                 
                  </article>     
            </div> */}

            {/* <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Plumbing Works</h3>
                     <p> Whatever your plumbing needs may be, count on us! We have been serving both residential and commercial properties in Uganda. We pride ourselves on our plumbing engineers and technicians considering their high level of expertise.</p>
                     <p>Dependability and innovation is part of what makes us unique.</p>
                  </article>

                  <article className='service-slides'>
                    <Plumbing></Plumbing>
                 
                  </article>     
            </div> */}

            {/* <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Project Management
</h3>
                <p>
                Our multidisciplinary project teams and unique technical solutions will ensure that all projects meet technical, economic, environmental and safety requirements. Our specialists work in close coordination with local partners to ensure end-to-end project management, from site investigation and feasibility phases through to final delivery to the operator.
                </p>
                <p><strong>Beda Civil Works & Contractors Limited</strong> also profits from its experience in the products developments sectors to introduce system engineering processes and tools to master the complexity of projects and to optimise costing and scheduling.</p>
                  </article>

                  <article className='service-slides'>
                    <AC></AC>
                 
                  </article>     
            </div> */}

              {/* <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Farming Services</h3>
                     <p>We provide the very best in powerful farm machinery and vehicles such as genuine Massey Fergusson tractors, ploughs and harrows, crop sprayers and post-hole diggers.</p>
                     <p>We provide farming implements and tools for small, medium and large-scale agriculture. All our equipment is chosen for durability and low running costs, and they are backed by the expert installation services and support of field technical team. </p>
                  </article>

                  <article className='service-slides'>
                    <Painting></Painting>
                 
                  </article>     
            </div> */}

          
            
           </div>

    </section>
  {/* <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.663), rgba(0, 0, 0, 0.663)), url("./images/34.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'8rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3'>Our Pledge</h2>
            <div className='pledge-line'></div>
         <p> <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span>We pledge to maintain the highest level of quality assurance and ensure unequalled customer satisfaction.

</p>
        </div>
    </section> */}
    
 {/* <section className='section-cover'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Goals</h2>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'><strong> REJO ENGINEERING LIMITED</strong> began as a general works contractor in 2014. Over the
years, the company has undertaken many challenging projects and accumulated skills, know-how and
experiences in delivering building projects, water projects, road and bridge construction
projects and build solutions, project management services, building trades and related
engineering works.
<br></br> <br></br>
Today, <strong>REJO ENGINEERING LIMITED</strong> takes on the role of main contractor for small,
medium and large size construction projects and performs project management services to
coordinate specialist trades for industrial/commercial projects. We also provide design inputs
and engineering solutions as value-add services to our clients.
<br></br> <br></br>
We take pride in our delivery, thus our clients can always be assured that only the most experienced and qualified people are serving them, all the time.</p>
                     </div>


                </div>
                   

           </article>

           <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               

           </article>

       </div>

  </section> */}

 
 {/* <section className='section-cover section-cover2' style={{ backgroundImage: `linear-gradient(  rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.863)), url("./images/4.jpg")`,backgroundAttachment:'fixed',backgroundSize:'cover', backgroundPosition:'center'
    }}>
       <div className='content-container goals-container'>
           <article className='goals-text goals-text1 goals-text2 goals-text3'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Core Values</h2>
              <h3 className='title-h3'>Our Objectives</h3>
              <div className='vm-line'></div>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description goal-description-a'> To provide the most efficient installation, repair
and maintenance solutions for clients’
dependable work continuity.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description goal-description-a'>To ensure timely, correct and best quality
delivery of services and supplies.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description goal-description-a'>To grow and maintain trusted relationships
with our clients, partners and government.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description goal-description-a'>To foster client up-to-date market awareness.

</p>
                     </div>
                    
                </div>
                   

           </article>

           




       </div>

  </section> */}

   {/* <section className='section-cover'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Objectives</h2>
              <div className='goals-list'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Our people are the greatest asset and we shall endeavor to continuously develop
our human resource to create a competitive advantage in our expertise.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Our processes guarantee quality and sustainability. We shall build world
class processes and implement certified Integrated Management Systems.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>To become a preferred EPC Contractor, we need to boost our brand visibility
through targeted marketing activities.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Improve our customer service through talent acquisition and close interaction and
collaboration with our customers.</p>
                     </div>

                     
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Our Plant & Machinery are state of the art and we shall endeavor to continuously
improve our asset base.</p>
                     </div>
<Link to="who-we-are" className='home-btn' onClick={scrollFunc}>
         More About Us
         </Link>
                </div>
                   

           </article>

           <article className='goals-slides'>
               <VisionCarousel></VisionCarousel>
               

           </article>




       </div>

  </section> */}

  {/* <section className='section-cover clients-cover' >
          <h2 className="home-h2">Our Clients</h2>
           <div className='line1'> 
           <div className='line2'></div>
           </div>
          <p className='clients-para'></p>
        <div className='content-container clients'>

           <article className='client'>
                      <img src='./images/1.png' alt='client' className='client-image'></img>
           </article>

            <article className='client'>
                      <img src='./images/2.jpg' alt='client' className='client-image'></img>
           </article>

            <article className='client'>
                      <img src='./images/3.png' alt='client' className='client-image'></img>
           </article>
           <article className='client'>
                      <img src='./images/4.png' alt='client' className='client-image'></img>
           </article>

            <article className='client'>
                      <img src='./images/5.png' alt='client' className='client-image'></img>
           </article>

          </div> 
    </section> */}
   
    
  
    </>
  )
}

export default Home