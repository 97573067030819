import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel';
import Plumbing from '../components/plumbing';
import AC from '../components/AC';
import Painting from '../components/Painting';
import Doors from '../components/Doors';
import Al from '../components/Al';
import Carpentry from '../components/Carpentry';
import Tile from '../components/Tile';
import Cleaning from '../components/Cleaning';
import Supply from '../components/Supply';
import GS from '../components/GS';
let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}


const Services = () => {

useEffect(()=>{
document.title = 'Our Services | Together As One Investments Ltd';

},[]);


  return (
    <>
     <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8)), url("./images/8.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'8rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3 quote-h3-a'>What We Do</h2>
            
        </div>
    </section>
    <section className='section-cover services-cover' >
      {/* <h2 className="home-h2 services-h2">Our Services</h2> */}
           <div className='content-container services-container'>
              <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Import & Supply of Agro Products</h3>                    
<ul>
    <li>Coffee</li> 
<li>Maize Flour</li>
<li>Diary Products</li>
<li>Bakery</li>
<li>Sugar</li>
</ul>
 {/* <p className='service-desc'>With guarantee on all our electrical supplies and dependable assurance on all our electrical solutions.</p> */}
                  {/* <Link to='#' className='home-link'>See Details</Link> */}
                  </article>

                  <article className='service-slides'>
                    <EmergencyCarousel></EmergencyCarousel>
                 
                  </article>     
            </div>

            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Construction Works
</h3>
<ul>
<li>Construction of new housing structures from foundation to completion.</li>
<li>Renovation works.</li>
<li>Design and build projects.</li>
<li>Water supply systems.</li>
<li>Production and erection of precast concrete suspended floor slabs (ECO SLABS).</li>
<li>Construction of roads.</li>
</ul>
                  </article>

                  <article className='service-slides'>
                    <SupportCarousel></SupportCarousel>
                 
                  </article>     
            </div>

            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>General Supplies</h3>
                       {/* <p><strong>Beda Civil Works & Contractors Limited</strong> continues its success by offering an unparalleled level of diversity and experience in Construction and Maintenance of Road Infrastructure. </p>                 
                <p> We have the capacity and skills to undertake any road construction or repair project. We have excellent expertise in Structural & Geometric Road Design, Road & Highway Construction, Surface Dressing, Asphalt and concrete paving, Pothole Patching, General Road and Highway Maintenance and Road Marking & Signs.</p> */}
           {/* <ul>
           <li><strong>Client:</strong> Musana Empowerment Centre</li> 
<li><strong>Location:</strong> Iganga District</li>
<li><strong>Period:</strong> December 2022 - March 2023</li>
<li><strong>Value:</strong> Ugx 400,000,000</li>
</ul> */}
                  </article>

                  <article className='service-slides'>
                    <MaintCarousel></MaintCarousel>
                 
                  </article>     
            </div>
          

           

 
           </div>

    </section>
    </>
  )
}

export default Services