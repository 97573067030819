import Carousel from 'react-bootstrap/Carousel';

function EmergencyCarousel() {
  return (
    <Carousel indicators={false} className='services-slides projects-slides'>     
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/29a.jpeg"
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/34.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/35.png"
          alt="Third slide"
        />
      </Carousel.Item>
     
    </Carousel>
  );
}

export default EmergencyCarousel;